@use '~@angular/material' as mat;
@import './theme.scss';

$primary-color: mat.get-color-from-palette($op-primary);
$accent-color: mat.get-color-from-palette($op-accent);
$warn-color: mat.get-color-from-palette($op-warn);

$accent-color-500: #2196f3;
$accent-color-100: mat.get-color-from-palette($op-accent, 100);
$accent-color-50: mat.get-color-from-palette($op-accent, 50);

$input-icon: #32323299;
$form-field-underline: #e5e7eb;
