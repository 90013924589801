@use '~@angular/material' as mat;
@import 'base';
@import 'components';
@import 'utilities';
@import 'variables';
@import 'theme';
@include mat.all-component-themes($op-theme);

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
  letter-spacing: 0.04rem;
  @apply bg-background-dark;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #c4c4c4;
}
h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 6rem;
  line-height: 7rem;
}
h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 3.75rem;
  line-height: 4.375rem;
}
h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3.5rem;
}
h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 2.125rem;
  line-height: 2.5rem;
}
h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.75rem;
}
h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.4375rem;
}
p {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;
}

a {
  color: $primary-color;
}

button:focus {
  outline: none;
}

.mat-icon-outline {
  @extend .material-icons !optional;
  font-family: 'Material Icons Outlined', serif !important;
}

.mat-button-disabled {
  &.mat-primary {
    @apply opacity-50;
  }
  &.mat-accent {
    color: $accent-color !important;
    @apply opacity-50;
  }
}

.profile-menu {
  min-width: 16.125rem !important;
  max-width: initial !important;
}

.snackbar {
  background: $accent-color;
  color: white;
}

.snackbar.error {
  background: $warn-color;
}

.dynamsoft-dwt-installdlg-iconholder {
  display: none;
}


#dwtcontrolContainer {
  overflow: scroll !important;
}
